import _encode from "./encode";
import _decode from "./decode";
import _format from "./format";
import _parse from "./parse";
var exports = {};
exports.encode = _encode;
exports.decode = _decode;
exports.format = _format;
exports.parse = _parse;
export default exports;
export const encode = exports.encode,
      decode = exports.decode,
      format = exports.format,
      parse = exports.parse;